html {
	height: 100%;

	body {
		height: 100%;

		#root {
			height: 100%;
			background-color: var(--bs-primary);
			background-image: url(../../img/bg.svg);
			background-position: center;
			background-size: cover;
			display: flex;
			flex-direction: column;

			.dashboard-wrapper {
				min-height: 100%;
				width: 100%;

				header {
					button.custom-dropdown {
						background: none !important;
						border: none !important;

						&::after {
							content: unset !important;
						}

						> img {
							width: 45px;
							height: 45px;
							border-radius: 50%;
						}
					}
				}

				main {
					overflow-y: auto;
				}
			}
		}
	}
}
