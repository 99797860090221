// react toastify
@import '~react-toastify/dist/ReactToastify.css';

// bootstrap
@import '~bootstrap/scss/bootstrap';

// utilities
@import './utilities';

// variables
@import './variables';

// custom stylings
@import './customs/global';
