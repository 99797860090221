// $enable-important-utilities: true;

// $utilities: map-merge(
// 	$utilities,
// 	(
// 		'width':
// 			map-merge(
// 				map-get($utilities, 'width'),
// 				(
// 					responsive: true,
// 				)
// 			),
// 		'height':
// 			map-merge(
// 				map-get($utilities, 'height'),
// 				(
// 					responsive: true,
// 				)
// 			),
// 	)
// );

.h-15 {
	height: 15% !important;
}

.h-85 {
	height: 85% !important;
}

.w-15 {
	width: 15% !important;
}

.w-85 {
	width: 85% !important;
}

.w-20 {
	width: 20% !important;
}

.w-80 {
	width: 80% !important;
}

.w-special {
	width: 255px !important;
}

@media (min-width: 768px) {
	.h-md-100 {
		height: 100% !important;
	}
	.w-md-75 {
		width: 75% !important;
	}
}

@media (min-width: 992px) {
	.w-lg-75 {
		width: 75% !important;
	}
}

// Orders page responsiveness
.fluid {
	width: 100%;
}

@media screen and (max-width: 629px) {
	.fluid {
		width: 97vw;
	}
}
